import React from "react";
import BaseLayout from "../components/baseLayout";
import HeroTitle from "../components/heroTitle";
import { useStaticQuery, graphql } from "gatsby";
import ArticleList, { article } from "../components/ArticleList";

export default function Projects() {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//content/projects//" } }
        sort: { order: DESC, fields: [frontmatter___order] }
      ) {
        edges {
          node {
            frontmatter {
              name
              description
              path
              img {
                childImageSharp {
                  gatsbyImageData(width: 350, height: 350)
                }
              }
            }
          }
        }
      }
    }
  `);

  const list: article[] = data.allMarkdownRemark.edges.map(({ node }: any) => ({
    title: node.frontmatter.name,
    description: node.frontmatter.description,
    path: node.frontmatter.path,
    date: null,
    img: node.frontmatter.img.childImageSharp.gatsbyImageData,
  }));

  return (
    <BaseLayout>
      <HeroTitle title="Projects" description={null} subtitle={null} />
      <ArticleList list={list} />
    </BaseLayout>
  );
}
